import React from "react";
import '../stylesheet/about_us/aboutus.css';

import AboutUsImg from '../images/aboutus.png';

export const AboutUS = () => {

  return (
    <div>
      <img
        style={{ width: "100%", height: '100vh' }}
        src={AboutUsImg}
        alt="about Us Image"
      />
    </div>
  );
}