import React from "react";
import '../stylesheet/connect_with_us/connectwithus.css'
import ConnectWithUs  from '../images/ConnectWithUsSunflowertool.png'

export const ConnetWithUs = () => {
  return (

    <div>
      <img 
        style={{backgroundSize: 'cover', backgroundPositon:'center',
          height: '100vh', width: '100%'
        }}
        src={ConnectWithUs} alt="connect with Us Image"
      />
    </div>
    );
}