import React from "react";
import '../stylesheet/tenkeys_stylesheet/tenkeys.css';
import key1 from "../images/10Kimages/Key1.png"
import key2 from "../images/10Kimages/Key2God'sHealing.png";
import key3 from "../images/10Kimages/Key3TeachableHearts.png";
import key4 from "../images/10Kimages/Key4God'sWord.png";
import key5 from "../images/10Kimages/Key5God'sPresence.png";
import key6 from "../images/10Kimages/Key6Panel3.png";
import key61 from "../images/10Kimages/Key6Panel 1.png"
import key62 from "../images/10Kimages/Key6Panel 2.png"
import key7 from "../images/10Kimages/Key7God'sNurturing.png";
import key8 from "../images/10Kimages/Key8Communion.png";
import key9 from "../images/10Kimages/Key9Renewal ofMinds.png";
import key10 from "../images/10Kimages/Key10AbidingGivers.png";

export const TenKeys = () => {

    return (
        <div className="tenkeysMain">
            <div style={{color:"#006400", marginBottom:"10%"}}>
                <h1 style={{marginLeft:'5%', marginBottom:'2%', textAlign:'center'}}>
                    <strong>10 Spiritual Keys For Thriving Together in Today’s World</strong>
                </h1>
                <h6 style={{textAlign: "center", marginBottom:'2%', color:'black'}}>
                    <strong>Key: a tool to unlock; a tool to start something; a tool used often to unlock and open a door. </strong> 
                </h6>

                <h2 style={{textAlign: "center", marginBottom:'5%'}}>
                    Learn More About One Key &#x2015; Together &#128525; &#128525; &#128525; &#128525;
                </h2>

                <h5 style={{marginBottom: '2%', textAlign:'center'}}>
                    <strong>As you start:</strong>
                </h5>

                <h5 style={{marginBottom: '2%', marginLeft:'2%'}}>
                    &#10070; <strong>Ask </strong> God’s Holy Spirit for guidance. Pray &#x2015; anticipating insights.
                </h5>

                <h5 style={{marginBottom: '2%', marginLeft: '2%'}}>
                    &#x2756; <strong>Review </strong> where the 10 Keys are located in the Four-Petal Sunflower Picture. (Found on home page.)
                </h5>

                <h5 style={{marginBottom: '5%', marginLeft:'2%'}}>
                    &#x2756; <strong>Remember </strong> 
                    these cool thoughts: One sunflower is made up of many little flowers &#x2015; it’s a community!
                    <h5 style={{marginLeft:'320px'}}>Similarly, each Four-Petal Sunflower represents a community of people!</h5>
                    
                </h5>
                
                <h5 style={{marginBottom: '2%', textAlign:'center'}}>
                    <strong>Three Together Steps:</strong>
                </h5>
                <div style={{marginLeft:'2%'}}>
                    
                       <h5 style={{marginBottom: '2%'}}>
                        <strong>1. Look</strong>  below and choose one of the ten Keys. </h5> 
     
               
                        <h5 style={{marginBottom:'3%'}}><strong>2. Read</strong>  out loud all or some of the Bible verses for your key.</h5> 
         

                        <h5 style={{marginBottom: '2%'}} ><strong>3. Enjoy </strong>
                           
                            talking about anything sparked by #1 and #2 steps. Only If helpful, consider one of the following:

                        </h5>
                        <div style={{marginLeft:'10%'}}>
                            <h5 style={{marginBottom: '2%', marginTop: '2%'}} >
                            &#10070;   Do you have any thoughts, impressions or feelings about your key?
                            </h5>
                            <h5 style={{marginBottom: '2%'}} >
                            &#10070;  How does your key help you connect with God’s love?
                            </h5>
                            <h5 style={{marginBottom: '2%'}} > 
                            &#10070;    How does your key help you love others more?
                            </h5>
                            <h5 style={{marginBottom: '2%'}} >
                            &#10070;   How does your key help you thrive together in today’s world? 
                            </h5>
                        </div>

                </div> 

            </div>
                
                <h1 style={{color:"#006400", textAlign: 'center'}}><strong>1. God's Glory</strong></h1>
                <h5 style={{color:"#006400", textAlign: 'center', marginBottom:'2%'}}>The sunflower faces and follows the sun. <br>
                </br>We face Jesus and through the Spirit follow Him.
                </h5>
            <img 
            style={{width:"100%", marginBottom:"10%"}}
            src={key1} alt ="Key1" />


                <h1 style={{color:"#006400", textAlign: 'center'}}><strong>2. God's Healing</strong></h1>
                <h5 style={{color:"#006400", textAlign: 'center', marginBottom:'2%'}}>God heals and nurtures us.</h5>             
            <img 
            style={{width:"100%", marginBottom:"10%"}}
            src={key2} alt ="Key2" />


            <h1 style={{color:"#006400", textAlign: 'center'}}><strong>3. Teachable Hearts</strong></h1>
            <h5 style={{color:"#006400", textAlign: 'center', marginBottom:'2%'}}>Are we good soil?</h5>            
            <img 
            style={{width:"100%", marginBottom:"10%"}}
            src={key3} alt ="Key3" />


                <h1 style={{color:"#006400", textAlign: 'center'}}><strong>4. God's Word</strong></h1>
                <h5 style={{color:"#006400", textAlign: 'center', marginBottom:'2%'}}>On His law we meditate day and night.</h5>
            <img 
            style={{width:"100%", marginBottom:"10%"}}
            src={key4} alt ="Key4" />


                <h1 style={{color:"#006400", textAlign: 'center'}}><strong>5. God's Presence</strong></h1>
                <h5 style={{color:"#006400", textAlign: 'center', marginBottom:'2%'}}> We shall never thirst.</h5>
            <img 
            style={{width:"100%", marginBottom:"10%"}}
            src={key5} alt ="Key5" />


                <h1 style={{color:"#006400", textAlign: 'center'}}><strong>6. God's Love Strenghtens Our Hearts</strong></h1>
                <h5 style={{color:"#006400", textAlign: 'center', marginBottom:'2%'}}>We anchor in His pure love.</h5>

            <img style={{width: "100%", marginBottom:"10%"}}
            src={key61} alt="6.1" />

            <img style={{width: "100%", marginBottom:"10%"}}
            src={key62} alt="6.2" />

            <img 
            style={{width:"100%", marginBottom:'10%'}}
            src={key6} alt ="Key6" />


                <h1 style={{color:"#006400", textAlign: 'center'}}><strong>7. God's Nurturing</strong></h1>
                <h5 style={{color:"#006400", textAlign: 'center', marginBottom:'2%'}}>His compassion is with us and among us.</h5>
            <img 
            style={{width:"100%", marginBottom:"10%"}}
            src={key7} alt ="Key7" />


                <h1 style={{color:"#006400", textAlign: 'center'}}><strong>8. Communion</strong></h1>
                <h5 style={{color:"#006400", textAlign: 'center', marginBottom:'2%'}}>Love attachments grow.</h5>
            <img 
            style={{width:"100%", marginBottom:"10%"}}
            src={key8} alt ="Key8" />


                <h1 style={{color:"#006400", textAlign: 'center'}}><strong>9. Renewal of Minds</strong></h1>
                <h5 style={{color:"#006400", textAlign: 'center', marginBottom:'2%'}}>We practice God thoughts<small>.<sub style={{verticalAlign:"super", fontSize:'medium'}}>1</sub></small></h5>
            <img 
            style={{width:"100%", marginBottom:"10%"}}
            src={key9} alt ="Key9" />


                <h1 style={{color:"#006400", textAlign: 'center'}}><strong>10. Abiding Givers</strong></h1>
                <h5 style={{color:"#006400", textAlign: 'center', marginBottom:'2%'}}>His yoke is easy.</h5>
            <img 
            style={{width:"100%", marginBottom:"10%"}}
            src={key10} alt ="Key10" />


            <div>

                <h2>Endnotes: </h2>
                    <h3 style={{marginBottom: '3%'}}><span style={{fontSize:'small'}}>1</span><sub style={{ fontSize:"Large"}}>Credit: Michel Hendricks, Life Model Works.</sub> </h3>

                <small style={{fontSize:'large'}}>All Scripture in the Ten Keys above are from the <br></br>  
                    English Standard Version of the Bible.
                </small> 
            </div>

        </div>
    )
}