
import React from "react";

export const EphesiansVerse = () => {

    return (
      <div>
        <h1 style={{ textAlign: "center", marginTop: "10%" }}>
            Ephesians 3:14-21
        </h1>

        <h5 style={{textAlign: "center", marginLeft: "30%", marginRight:"30%"}}>
            <p>When I think of all this, I fall to my knees and pray to the Father, </p>
            <p>the Creator of everything in heaven and on earth. I pray that from his </p>
            <p>glorious, unlimited resources he will empower you with inner strength</p>
            <p>through his Spirit. Then Christ will make his home in your hearts as</p>
            <p>you trust in him. Your roots will grow down into God’s love and keep</p>
            <p>you strong. And may you have the power to understand, as all God’s</p>
            <p>people should, how wide, how long, how high, and how deep his love is.</p>
            <p>May you experience the love of Christ, though it is too great to</p>
            <p>understand fully. Then you will be made complete with all the fullness</p>
            <p>of life and power that comes from God. Now all glory to God, who is</p>
            <p>able, through his mighty power at work within us, to accomplish</p>
            <p>infinitely more than we might ask or think. Glory to him in the church</p>
            <p>and in Christ Jesus through all generations forever and ever! Amen.</p>
            Ephesians 3:14-21 NLT
        </h5>
      </div>
    );
}